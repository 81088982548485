<template>
  <SelectRoot v-bind="forwarded" class="SelectRoot" @update:open="handleFocusTrap">
    <SelectTrigger as-child>
      <slot name="trigger">
        <DropdownButton :is-error="isError" :disabled="disabled">
          <SelectValue :placeholder="placeholder" />
        </DropdownButton>
      </slot>
    </SelectTrigger>

    <SelectPortal>
      <SelectContent>
        <slot></slot>
      </SelectContent>
    </SelectPortal>
  </SelectRoot>
</template>

<script setup lang="ts">
import {
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectPortal,
  useForwardPropsEmits,
  type SelectRootEmits,
  type SelectRootProps,
} from "radix-vue";

interface Props extends SelectRootProps {
  placeholder?: string;
  isError?: boolean;
  disabled?: boolean;
}

const props = defineProps<Props>();
const emits = defineEmits<SelectRootEmits>();
const forwarded = useForwardPropsEmits(props, emits);

const { handleFocusTrap } = useInjectFocusTrap();
</script>

<style lang="scss">
.SelectRoot {
  position: relative !important;
}
</style>
